<template>
  <v-btn color="accent" v-on:click="signIn()">
    <v-icon>mdi-microsoft-office</v-icon> Anmelden
  </v-btn>
</template>

<script>
import Auth from '@aws-amplify/auth'

export default {
  name: 'SignIn',
  methods: {
    signIn () {
      Auth.federatedSignIn({ provider: process.env.VUE_APP_OAUTH_PROVIDER }).catch(e => {
        console.error(e)
      })
    }
  }
}
</script>
