<template>
  <v-row
      align="center"
      justify="space-around"
  >
    <sign-in></sign-in>
  </v-row>
</template>

<script>
import SignIn from '@/components/SignIn.vue'

export default {
  components: {
    SignIn
  }
}
</script>
